import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"

const Image = styled.img`
  width: 400px;
`
export const pageQuery = graphql`
  query IndexQuery {
    allStrapiArt {
      edges {
        node {
          id
          name
          image {
            publicURL
          }
        }
      }
    }
  }
`

const ContactPage = ({ data }) => (
  <Layout>
    <SEO title="Galleria" />
    <h1>Galleria</h1>
    <ul>
      {data.allStrapiArt.edges.map(document => (
        <li key={document.node.id}>
          <h2>
            <Link to={`/art/${document.node.id}`}>{document.node.name}</Link>
          </h2>
          <Image src={document.node.image.publicURL} alt={document.node.name} />
        </li>
      ))}
    </ul>
    <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}></div>
  </Layout>
)

export default ContactPage
